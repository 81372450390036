var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"individual"},[_c('div',{staticClass:"individual__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"individual__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"to-set":function (e) { return e.userId; },"fixed-header":"","height":"527px","show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"tags",fn:function(ref){
var row = ref.row;
return [(row.userTagList)?_c('div',{staticClass:"d-flex align-center"},_vm._l((row.userTagList),function(tag){return _c('Tag',{key:'tag-row-' + tag.id,attrs:{"color":tag.color,"class-name":"mr-1"}},[_vm._v(" "+_vm._s(tag.tagName)+" ")])}),1):_vm._e()]}},{key:"dailyLimits",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getLimitBriefList(row, _vm.constant.transactions.LIMIT_PERIOD.DAY))+" ")]}},{key:"monthlyLimits",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getLimitBriefList(row, _vm.constant.transactions.LIMIT_PERIOD.MONTH))+" ")]}},{key:"referralProgram",fn:function(ref){
var row = ref.row;
return [(row.referralList.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")]):_c('span',[_vm._v("–")])]}},{key:"staking",fn:function(ref){
var row = ref.row;
return [(row.stakingList.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")]):_c('span',[_vm._v("–")])]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }