//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SettingsIndividual from './SettingsIndividual/SettingsIndividual.vue';
import SettingsLimits from './SettingsLimits/SettingsLimits.vue';

export default {
  components: {
    SettingsIndividual,
    SettingsLimits,
  },
};
