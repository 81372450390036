export default [
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'User',
      sort: 'id',
    },
    cell: {
      type: 'slot',
      name: 'user',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Tags',
    },
    cell: {
      type: 'slot',
      name: 'tags',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Daily limits',
    },
    cell: {
      type: 'slot',
      name: 'dailyLimits',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Monthly limits',
    },
    cell: {
      type: 'slot',
      name: 'monthlyLimits',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Referral program',
    },
    cell: {
      type: 'slot',
      name: 'referralProgram',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Staking',
    },
    cell: {
      type: 'slot',
      name: 'staking',
    },
  },
];
