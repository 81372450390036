//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import UserSelect from '~/components/UserSelect.vue';
import constant from '~/const';

export default {
  components: {
    UserSelect,
  },

  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        userIdList: [],
        typeList: [],
        userTagList: [],
      },
      constant,
    };
  },

  computed: {
    ...mapState('settingsIndividual', ['filterLists']),
    ...mapState('users', ['userTagsList']),

    userIdList: {
      get() {
        return this.filters.userIdList[0];
      },
      set(value) {
        this.filters.userIdList = value
          ? [value]
          : [];
      },
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('settingsIndividual', ['setFilters']),
  },
};
