//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './SettingsIndividualFilters.vue';
import Extra from './SettingsIndividualExtra.vue';
import User from '~/components/User.vue';
import Tag from '~/components/tags/Tag.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: {
    Filters,
    Extra,
    User,
    Tag,
  },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      addingUser: {
        modal: false,
        userId: 0,
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('users', ['getUserTagsByIds']),

    ...mapState('settingsIndividual', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification']),
    ...mapActions('settingsIndividual', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralLoader(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralLoader(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getLimitBriefList(row, period) {
      const list = row.limitList.find((e) => e.period === period);
      if (list) {
        return constant.transactions.LIMIT_TYPE_LIST
          .filter((e) => {
            const limit = list[`${(e.id[0]).toLowerCase()}${e.id.slice(1)}`];
            return Number.isFinite(limit);
          })
          .map((e) => e.caption)
          .join('/');
      }
      return '–';
    },
  },
};
