//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import SettingFeesAndLimits from '~/components/modals/SettingFeesAndLimits.vue';
import constant from '~/const';

export default {
  components: { SettingFeesAndLimits },

  data() {
    return {
      settingFeesAndLimits: {
        modal: false,
        filter: () => {},
        payload: null,
      },
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapState('settingsLimits', ['data']),
    ...mapGetters('currencies', ['getCurrencyById']),
    ...mapGetters('users', ['getUserTagByTagName']),

    config() {
      const vipTagId = this.getUserTagByTagName('vip')
        ? this.getUserTagByTagName('vip').id
        : 0;

      return {
        range: [
          { title: this.$t('Daily limits'), value: constant.transactions.LIMIT_PERIOD.DAY },
          { title: this.$t('Monthly limits'), value: constant.transactions.LIMIT_PERIOD.MONTH },
        ],
        types: constant.transactions.LIMIT_TYPE_LIST.map((e) => ({
          title: e.caption,
          value: `limit${e.id}`,
        })),
        groups: [
          {
            title: this.$t('Not verificated'),
            payload: { verificated: false },
            filter: (e) => e.verificated === false,
          },
          {
            title: this.$t('Verificated'),
            payload: { verificated: true },
            filter: (e) => e.verificated === true,
          },
          {
            title: this.$t('VIP'),
            payload: { tagIdList: [vipTagId] },
            filter: (e) => e.tagIdList.includes(vipTagId),
          },
        ],
      };
    },

    filteredData() {
      const { data } = this;
      const { filter } = this.settingFeesAndLimits;
      return data.filter(filter);
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('settingsLimits', {
      updateTransactionLimitAction: 'updateTransactionLimit',
      deleteTransactionLimitAction: 'deleteTransactionLimit',
      loadDataAction: 'loadData',
    }),

    getValue(range, type, filter) {
      const { data } = this;
      const value = data
        .filter(filter)
        .find((e) => e.limitPeriod === range);
      if (value && Number.isFinite(value[type])) {
        const currency = this.getCurrencyById(value.currencyId);
        return `${value[type]} ${currency.title}`;
      }
      return '–';
    },

    edit(filter, payload) {
      this.settingFeesAndLimits.modal = true;
      this.settingFeesAndLimits.filter = filter;
      this.settingFeesAndLimits.payload = payload;
    },

    async applySetFeesAndLimits({ state, currencyId }) {
      const { types } = this.config;
      const { payload: addPayload } = this.settingFeesAndLimits;
      const payload = state.map((e) => ({
        ...e,
        ...addPayload,
        currencyId,
      }));
      this.setGeneralProgress(true);
      try {
        await Promise.allSettled(
          payload.map((e) => {
            if (types.some((t) => e[t.value])) {
              return this.updateTransactionLimitAction(e);
            }
            if (e.id) {
              return this.deleteTransactionLimitAction({
                limitId: e.id,
              });
            }
            return null;
          }),
        );
        await this.loadDataAction();
        this.settingFeesAndLimits.modal = false;
        this.setSuccessNotification('Limits changed successfully');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },
  },
};
