//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import SettingFeesAndLimits from '~/components/modals/SettingFeesAndLimits.vue';
import constant from '~/const';

export default {
  components: {
    SettingFeesAndLimits,
  },

  props: {
    data: { type: Object, required: true },
  },

  data() {
    return {
      settingFeesAndLimits: {
        modal: false,
      },
      constant,
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapGetters('currencies', ['getCurrencyById']),

    config() {
      return {
        range: [
          { title: this.$t('Daily'), value: constant.transactions.LIMIT_PERIOD.DAY },
          { title: this.$t('Monthly'), value: constant.transactions.LIMIT_PERIOD.MONTH },
        ],
        types: constant.transactions.LIMIT_TYPE_LIST.map((e) => ({
          title: e.caption,
          value: `limit${e.id}`,
        })),
      };
    },

    limitList() {
      const { limitList } = this.data;
      return limitList.map((e) => {
        const obj = {};
        Object.keys(e).forEach((k) => {
          obj[`limit${k[0].toUpperCase()}${k.slice(1)}`] = e[k];
        });
        obj.period = e.period;
        obj.currencyId = e.currencyId;
        return obj;
      });
    },

    stakingList() {
      const { stakingList } = this.data;
      return stakingList;
    },

    referralList() {
      const { referralList } = this.data;
      return referralList;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('settingsIndividual', {
      updateUserTransactionLimitAction: 'updateUserTransactionLimit',
      deleteUserTransactionLimitAction: 'deleteUserTransactionLimit',
      loadSettingsIndividual: 'loadData',
    }),

    getLimitValue(range, type) {
      const { limitList } = this;
      const value = limitList.find((e) => e.period === range);
      if (value && Number.isFinite(value[type])) {
        const currency = this.getCurrencyById(value.currencyId) || {};
        return `${value[type]} ${currency.title}`;
      }
      return '–';
    },

    edit() {
      this.settingFeesAndLimits.modal = true;
    },

    async applySetFeesAndLimits({ state, currencyId }) {
      const { userId } = this.data;
      const { types } = this.config;
      const payload = state.map((e) => ({
        ...e,
        userId,
        currencyId,
      }));
      this.setGeneralProgress(true);
      try {
        await Promise.allSettled(
          payload.map((e) => {
            if (types.some((t) => e[t.value])) {
              return this.updateUserTransactionLimitAction(e);
            }
            return this.deleteUserTransactionLimitAction({
              limitId: e.id,
            });
          }),
        );
        await this.loadSettingsIndividual();
        this.settingFeesAndLimits.modal = false;
        this.setSuccessNotification('Limits changed successfully');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },
  },
};
